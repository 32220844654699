import React from "react"
// import { Link } from "gatsby"
import { LiveAccountQuery } from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"

import Layout from "../../components/layout"
import {
  HeadingBanner,
  SubscriptionPanel,
  BreadCrumbBanner,
  QuickStats,
  MainContent,
  LiveAccountForm,
} from "../../components/shared"

// import { Tab, Tabs, Row, Nav, Col } from "react-bootstrap"
const OurMission = () => {
  const language = useSelector(state => state.language)
  const {
    banner_image,
    banner_title,
    page_heading,
    page_description,
    show_breadcrumb,
    show_buttons,
    show_risk_heading,
    show_stats,
    show_subscription_panel,
    page_under,
  } = LiveAccountQuery(language)
  return (
    <>
      <Layout>
        {show_breadcrumb === false && (
          <HeadingBanner
            heading={banner_title}
            banner_image={banner_image}
            show_breadcrumb={show_breadcrumb}
            show_buttons={show_buttons}
            show_risk_heading={show_risk_heading}
          />
        )}
        {show_breadcrumb === true && (
          <BreadCrumbBanner
            heading={banner_title}
            banner_image={banner_image}
          />
        )}
        {show_stats === true && <QuickStats />}
        <MainContent
          className={page_under}
          content={page_description}
          heading={page_heading}
        />

        <LiveAccountForm />

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
